import request from '@/utils/request'

//获取列表
// {current}/{limit}

export function addform(data) {
  return request({
    url: '/officialWebsite/addContactInfo',
    method: 'post',
    data: data,
  })
}
