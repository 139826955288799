// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

// Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]

// const router = new VueRouter({
//   routes
// })

// export default router
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import more from '../views/more.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',

    component: HomeView,
    meta: {
      noCacheScrollPosition: true, // 添加一个标识表示不缓存滚动位置
    },
  },
  {
    path: '/more',
    name: 'more',
    component: more,
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.noCacheScrollPosition) {
      return { x: 0, y: 0 }
    }
    // 如果没有特殊要求，可以返回 savedPosition 以恢复上一个页面的滚动位置
    return savedPosition || { x: 0, y: 0 }
  },
})

export default router
