import { render, staticRenderFns } from "./About_four.vue?vue&type=template&id=8a3f0fec&scoped=true"
import script from "./About_four.vue?vue&type=script&lang=js"
export * from "./About_four.vue?vue&type=script&lang=js"
import style0 from "./About_four.vue?vue&type=style&index=0&id=8a3f0fec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a3f0fec",
  null
  
)

export default component.exports