<template>
  <div>
    <!-- 顶部 -->
    <div class="top">
      <div class="left">
        <img class="logoimg" src="../assets/logo.png" alt="" />
        <div class="name">诚飞智慧科技</div>
      </div>
      <div class="right">
        <div
          class="tab-item"
          :class="{ active: activeTab === 'index' }"
          @click="scrollTo('index')"
        >
          首页
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'Aboutus' }"
          @click="scrollTo('Aboutus')"
        >
          关于我们
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'vision' }"
          @click="scrollTo('vision')"
        >
          愿景
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'team' }"
          @click="scrollTo('team')"
        >
          团队
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'product' }"
          @click="scrollTo('product')"
        >
          产品
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'model' }"
          @click="scrollTo('model')"
        >
          模式
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'contactus' }"
          @click="scrollTo('contactus')"
        >
          联系我们
        </div>
      </div>
    </div>

    <div id="index"><index></index></div>
    <div id="Aboutus"><Aboutus></Aboutus></div>
    <div id="vision"><vision></vision></div>
    <div id="team"><team></team></div>
    <div id="product"><product></product></div>
    <div id="model"><model></model></div>
    <div id="contactus"><contactus></contactus></div>
    <div id="bottom"><bottom></bottom></div>
  </div>
</template>

<script>
import index from './index.vue'
import Aboutus from './Aboutus.vue'
import model from './model.vue'
import vision from './vision.vue'
import team from './team.vue'
import product from './product.vue'
import contactus from './contactus.vue'
import bottom from './bottom.vue'

export default {
  name: 'CfsdCodeHomeView',
  components: {
    index,
    Aboutus,
    vision,
    team,
    product,
    bottom,
    contactus,
    model,
  },
  data() {
    return { activeTab: 'index' }
  },

  mounted() {
    window.addEventListener('scroll', this.checkActiveSection)
    this.checkActiveSection() // 在页面加载时立即检查一次活动部分
    this.activeTab = 'index' // 明确初始化为'index'
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkActiveSection)
  },

  methods: {
    checkActiveSection() {
      const sections = [
        'index',
        'Aboutus',
        'vision',
        'team',
        'product',
        'model',
        'contactus',
      ]
      let currentSection
      sections.forEach(section => {
        const targetElement = document.getElementById(section)
        if (targetElement) {
          const viewportHeight = window.innerHeight
          const elementHeight = targetElement.offsetHeight
          const topPosition = targetElement.offsetTop
          const bottomPosition = topPosition + elementHeight
          if (
            window.scrollY >= topPosition - viewportHeight / 2 &&
            window.scrollY < bottomPosition - viewportHeight / 2
          ) {
            currentSection = section
          }
        }
      })
      this.activeTab = currentSection
    },
    // scrollTo(section) {
    //   this.activeTab = section
    //   const targetElement = document.getElementById(section)
    //   if (targetElement) {
    //     if (section === 'index') {
    //       window.scrollTo({
    //         top: targetElement.offsetTop - 10,
    //         behavior: 'smooth',
    //       })
    //     }
    //     else {
    //       const viewportHeight = window.innerHeight
    //       const elementHeight = targetElement.offsetHeight
    //       const topPosition =
    //         targetElement.offsetTop - (viewportHeight - elementHeight) / 2
    //       window.scrollTo({
    //         top: topPosition,
    //         behavior: 'smooth',
    //       })
    //     }
    //   }
    // },
    scrollTo(section) {
      const targetElement = document.getElementById(section)
      console.log('targetElement', targetElement)

      if (targetElement) {
        if (section === 'index') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'Aboutus') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'vision') {
          window.scrollTo({
            top: targetElement.offsetTop - 50,
            behavior: 'smooth',
          })
        }
        if (section === 'team') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'product') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'model') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'contactus') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
      } else {
        this.$router.push('/').then(() => {
          const viewportHeight = window.innerHeight
          const targetElement = document.getElementById(section)
          if (targetElement) {
            const elementHeight = targetElement.offsetHeight
            const topPosition =
              targetElement.offsetTop - (viewportHeight - elementHeight) / 2
            window.scrollTo({
              top: topPosition,
              behavior: 'smooth',
            })
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1201px) {
  .tab-item.active {
    color: #000000;
    opacity: 1;
  }
  .tab-item {
    cursor: pointer;
    font-size: 14px;
    color: #000000;
    opacity: 0.6;
  }
  .top {
    background-color: #ffffff;
    height: 80px;
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 9999;
    top: 0;
    flex-wrap: wrap;
    z-index: 999;
    .right {
      width: 45%;

      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      .logoimg {
        width: 30px;
        height: 28px;
        margin-left: 80px;
      }
      .name {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 24px;
        color: #141314;
        font-weight: 500;
        margin-left: 7px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .tab-item.active {
    color: red;
  }
  .tab-item {
    cursor: pointer;
    font-size: 14px;
    color: #000000;
    opacity: 0.6;
  }
  .top {
    background-color: white;
    height: 120px;
    width: 100%;

    position: fixed;
    z-index: 9999;
    top: 0;
    flex-wrap: wrap;
    z-index: 999;
    .right {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .left {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      .logoimg {
        width: 30px;
        margin-left: 60px;
        height: 28px;
      }
      .name {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 24px;
        color: #141314;
        font-weight: 500;
        margin-left: 7px;
      }
    }
  }
}
</style>
