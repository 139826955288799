<template>
  <div>
    <div class="content">
      <div class="view">
        <div class="titleone">联系我们</div>
        <div class="titletwo">contact us</div>
        <div class="pop-up" v-if="showThirdRectangle">
          <img class="pop-upimg" src="../assets/pop-up.png" alt="" />
          <div class="pop-upicon">
            <div class="boss" @click="goToBoss">
              <div class="center">
                <img class="bossicon" src="../assets/boss.png" alt="" />
                <div class="bosstext">前往Boss</div>
              </div>
            </div>
            <div class="zhilian" @click="goToZhilian">
              <div class="center">
                <img class="zhilianicon" src="../assets/zhilian.png" alt="" />
                <div class="zhiliantext">前往智联</div>
              </div>
            </div>
          </div>
        </div>
        <div class="model">
          <div class="rectangle" @click="handleClick(1)">
            <div class="rectangle_content">
              <img class="iconimg" src="../assets/icon1.png" alt="" />

              <div class="title">商家入驻</div>
            </div>
          </div>
          <div class="rectangle" @click="handleClick(2)">
            <div class="rectangle_content">
              <img class="iconimg" src="../assets/icon2.png" alt="" />
              <div class="title">商务合作</div>
            </div>
          </div>
          <div class="rectanglethree" @click="handleAddClick">
            <div class="rectangle_content">
              <img class="iconimg" src="../assets/icon3.png" alt="" />
              <div class="title">加入我们</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form" v-if="contactInfoshow_one">
      <div class="phonetext">请留下您的联系方式</div>
      <div class="inputview">
        <input
          class="inputmodel"
          v-model="contactInfo"
          type="text"
          placeholder="请填写您的电话或邮箱"
        />
        <div class="submitbtn" @click="submitForm()">提交</div>
      </div>
    </div>
    <div class="form" v-if="contactInfoshow_two">
      <div class="phonetext">请留下您的联系方式</div>
      <div class="inputview">
        <input
          class="inputmodel"
          v-model="contactInfo"
          type="text"
          placeholder="请填写您的电话或邮箱"
        />
        <div class="submitbtn" @click="submitForm()">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { addform } from '../api/api'
export default {
  name: 'CfsdCodeContactUs',

  data() {
    return {
      contactInfoshow_one: false,
      contactInfoshow_two: false,
      contactInfo: '',
      type: '',
      showThirdRectangle: false,
    }
  },

  mounted() {},

  methods: {
    handleClick(val) {
      this.type = val
      if (this.type === 1) {
        this.contactInfoshow_one = !this.contactInfoshow_one
        this.contactInfoshow_two = false
      }
      if (this.type === 2) {
        this.contactInfoshow_one = false
        this.contactInfoshow_two = !this.contactInfoshow_two
      }
    },
    handleAddClick() {
      this.showThirdRectangle = !this.showThirdRectangle
      this.contactInfoshow_one = false
      this.contactInfoshow_two = false
    },
    submitForm() {
      if (this.contactInfo === '') {
        this.$message.error('请填写您的联系方式')
        return
      }
      const phoneRegex = /^1[3-9]\d{9}$/
      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
      if (
        !phoneRegex.test(this.contactInfo) &&
        !emailRegex.test(this.contactInfo)
      ) {
        this.$message.error('请输入正确的电话或邮箱')
        return
      }
      addform({ contactInfo: this.contactInfo, type: this.type })
        .then(response => {
          // 后端返回数据的处理
          console.log(response)

          // 提示提交成功
          this.$message({
            showClose: true,
            message: '提交成功',
            type: 'success',
          })
          this.type = ''
          this.contactInfo = ''
        })
        .catch(error => {
          // 处理错误情况

          // 返回 false 表示提交失败
          return false
        })
    },
    goToBoss() {
      window.location.href =
        'https://www.zhipin.com/sem/10.html?_ts=1728983945865&sid=sem_bdpc&qudao=bdpc_baidu-PC-BOSS02B22KA04269&plan=%E5%93%81%E7%89%8C%E8%AF%8D%E5%A2%9E%E6%8A%95zc&unit=%E5%AA%92%E4%BD%93&keyword=%E6%8B%9B%E8%81%98boss&bd_vid=12007599539262394247'
    },
    goToZhilian() {
      window.location.href =
        'https://www.zhipin.com/gongsi/job/d8c7305d1a202b650Hx43Ny6.html?ka=look-all-position'
    },
    // show() {
    //   this.showThirdRectangle = true
    // },
    // hidden() {
    //   setTimeout(() => {
    //     this.showThirdRectangle = false
    //   }, 1000)
    // },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .form {
    width: 90%;
    margin: 0 auto;

    .phonetext {
      padding: 60px 0;
      font-family: Alibaba-PuHuiTi-M;
      font-size: 30px;
      color: #000000;
      letter-spacing: 1.25px;
      text-align: center;
      font-weight: 500;

      width: 90%;
    }
    .inputview {
      padding: 60px 0;
      width: 100%;

      display: flex;

      .inputmodel {
        height: 50px;
        width: 100%;
      }
      .submitbtn {
        width: 20%;
        height: 55px;
        background-color: #000000;
        color: #ffffff;
        line-height: 55px;
        text-align: center;
      }
    }
  }
  .pop-up {
    position: relative;

    width: 80%;
    margin: 0 auto;
    .pop-upimg {
      margin-top: 60%;
      position: absolute;
      width: 100%;
    }
    .pop-upicon {
      position: absolute;
      display: flex;
      width: 100%;
      margin-top: 60%;

      .boss {
        width: 100%;

        .center {
          width: 90%;

          margin: 0 auto;
          margin-top: 10%;

          .bossicon {
            margin: 0 auto;
            display: flex;
            justify-content: center;
          }
          .bosstext {
            margin-top: 20%;
            font-family: Alibaba-PuHuiTi-R;
            font-size: 14px;
            color: #000000;
            letter-spacing: 0.61px;
            font-weight: 400;
            display: flex;
            justify-content: center;
          }
        }
      }
      .zhilian {
        width: 100%;

        .center {
          width: 90%;

          margin: 0 auto;
          margin-top: 10%;

          .zhilianicon {
            margin: 0 auto;
            display: flex;
            justify-content: center;
          }
          .zhiliantext {
            margin-top: 20%;
            font-family: Alibaba-PuHuiTi-R;
            font-size: 14px;
            color: #000000;
            letter-spacing: 0.61px;
            font-weight: 400;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
  .model {
    width: 90%;
    height: 100%;
    margin: 0 auto;

    margin-top: 80px;
    .rectanglethree:hover {
      background: rgba($color: #ffffff, $alpha: 0.5);
    }
    .rectangle,
    .rectanglethree {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(151, 151, 151, 0.6);
      width: 100%;
      height: 20%;
      margin-top: 5%;
      display: flex;
      align-items: center;
      .rectangle_content {
        width: 100%;

        margin: 0 auto;
        .iconimg {
          width: 20%;
          height: 20%;
          margin-left: 40%;
          display: flex;
          justify-content: center;
        }
        .title {
          margin-top: 40px;
          font-family: Alibaba-PuHuiTi-R;
          font-size: 22px;
          color: #ffffff;
          letter-spacing: 0.92px;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
  .content {
    height: 100vh;
  }
  .view {
    margin-top: 180px;
    background-image: url('../assets/bgbanner.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    .titleone {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 48px;
      color: #ffffff;
      letter-spacing: 2px;
      font-weight: 500;
      text-align: center;
      padding-top: 8%;
      margin: 0 auto;
    }
    .titletwo {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0.9px;
      text-align: center;
      line-height: 34px;
      font-weight: 400;
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: 1201px) {
  .form {
    width: 82%;
    margin: 0 auto;
    display: flex;

    justify-content: space-between;
    .phonetext {
      padding: 60px 0;
      font-family: Alibaba-PuHuiTi-M;
      font-size: 30px;
      color: #000000;
      letter-spacing: 1.25px;
      text-align: center;
      font-weight: 500;

      width: 20%;
    }
    .inputview {
      padding: 60px 0;
      width: 80%;
      display: flex;
      .inputmodel {
        height: 50px;
        padding-left: 20px;
        width: 80%;
        margin-left: 10%;
        margin-right: 5%;
      }
      .submitbtn {
        width: 20%;
        height: 55px;
        background-color: #000000;
        color: #ffffff;
        line-height: 55px;
        text-align: center;
      }
    }
  }
  .pop-up {
    position: relative;

    .pop-upimg {
      width: 20%;
      margin-left: 70%;
      margin-top: -5%;

      position: absolute;
    }
    .pop-upicon {
      position: absolute;
      display: flex;
      width: 20%;

      margin-left: 70%;
      .boss {
        width: 50%;

        .center {
          width: 50%;

          margin: 0 auto;
          margin-top: -20%;

          .bossicon {
            margin: 0 auto;
            display: flex;
            justify-content: center;
          }
          .bosstext {
            margin-top: 20%;
            font-family: Alibaba-PuHuiTi-R;
            font-size: 14px;
            color: #000000;
            letter-spacing: 0.61px;
            font-weight: 400;
            display: flex;
            justify-content: center;
          }
        }
      }
      .zhilian {
        width: 50%;

        .center {
          width: 50%;

          margin: 0 auto;
          margin-top: -20%;

          .zhilianicon {
            margin: 0 auto;
            display: flex;
            justify-content: center;
          }
          .zhiliantext {
            margin-top: 20%;
            font-family: Alibaba-PuHuiTi-R;
            font-size: 14px;
            color: #000000;
            letter-spacing: 0.61px;
            font-weight: 400;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
  .model {
    width: 82%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    .rectanglethree:hover {
      background: rgba($color: #ffffff, $alpha: 0.3);
    }
    .rectangle:hover {
      background: rgba($color: #ffffff, $alpha: 0.3);
    }
    .rectangle,
    .rectanglethree {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(151, 151, 151, 0.6);
      width: 30%;
      height: 35%;
      margin-top: 5%;
      display: flex;
      align-items: center;
      .rectangle_content {
        width: 30%;

        margin: 0 auto;
        .iconimg {
          width: 60%;
          height: 60%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
        }
        .title {
          margin-top: 40px;
          font-family: Alibaba-PuHuiTi-R;
          font-size: 22px;
          color: #ffffff;
          letter-spacing: 0.92px;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
  .content {
    height: 100vh;
  }
  .view {
    margin-top: 180px;
    background-image: url('../assets/bgbanner.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    .titleone {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 48px;
      color: #ffffff;
      letter-spacing: 2px;
      font-weight: 500;
      text-align: center;
      padding-top: 8%;
      margin: 0 auto;
    }
    .titletwo {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0.9px;
      text-align: center;
      line-height: 34px;
      font-weight: 400;
      margin: 0 auto;
    }
  }
}
</style>
