<template>
  <div class="bgcolor">
    <div class="content">
      <div class="one">站点导航</div>
      <div class="two">
        <div @click="scrollTo('index')">首页</div>
        <div @click="scrollTo('Aboutus')">关于我们</div>
        <div @click="scrollTo('vision')">愿景</div>
        <div @click="scrollTo('team')">团队</div>
        <div @click="scrollTo('product')">产品</div>
        <div @click="scrollTo('model')">模式</div>
        <div @click="scrollTo('contactus')">联系我们</div>
      </div>
      <div class="solid1"></div>
      <div class="info">
        <div class="emali">
          <img style="margin-right: 5px" src="../assets/email.png" alt="" />
          邮箱：official@cfzh25.wecom.work
        </div>
        <div>
          <div class="phone">
            <img style="margin-right: 5px" src="../assets/phone.png" alt="" />
            电话：400-166-2626
          </div>
        </div>
        <div>
          <div class="adress">
            <img style="margin-right: 5px" src="../assets/adress.png" alt="" />
            地址：江苏省南京市浦口区雨合路6号光电科技园
          </div>
        </div>
        <div>
          <div class="web">
            <img style="margin-right: 5px" src="../assets/gzhicon.png" alt="" />
            请关注公众号：二十六度节能科技
          </div>
        </div>
      </div>
      <div class="solid2"></div>
      <div class="bottom">
        <span>Copyright © 诚飞智慧科技（南京）有限公司</span>
        <img
          style="
            width: 24px;
            height: 24px;
            margin-left: 30px;
            margin-right: 5px;
          "
          src="../assets/bottomimg.png"
          alt=""
        />
        <a
          target="_blank"
          href="https://beian.mps.gov.cn/#/query/webSearch"
          style="text-decoration: none"
        >
          <span style="color: #ffffff"> 苏公网安备32011402012090号</span>
        </a>

        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          style="text-decoration: none; margin-left: 20px"
        >
          <span style="color: #ffffff">苏ICP备2024139085号-1</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeBottom',

  data() {
    return {}
  },

  mounted() {},

  methods: {
    // scrollTo(section) {
    //   // 假设这里有一个全局的方法或者事件总线可以调用父组件的 scrollTo 方法
    //   // 如果没有事件总线机制，可以考虑将 scrollTo 方法提取为一个独立的函数在全局范围内可用
    //   // 这里只是模拟调用父组件的 scrollTo 方法
    //   window.scrollTo({
    //     top:
    //       document.getElementById(section).offsetTop -
    //       (window.innerHeight - document.getElementById(section).offsetHeight) /
    //         2,
    //     behavior: 'smooth',
    //   })
    // },
    scrollTo(section) {
      const targetElement = document.getElementById(section)
      console.log('targetElement', targetElement)

      if (targetElement) {
        if (section === 'index') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'Aboutus') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'vision') {
          window.scrollTo({
            top: targetElement.offsetTop - 50,
            behavior: 'smooth',
          })
        }
        if (section === 'team') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'product') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'model') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
        if (section === 'contactus') {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          })
        }
      } else {
        this.$router.push('/').then(() => {
          const viewportHeight = window.innerHeight
          const targetElement = document.getElementById(section)
          if (targetElement) {
            const elementHeight = targetElement.offsetHeight
            const topPosition =
              targetElement.offsetTop - (viewportHeight - elementHeight) / 2
            window.scrollTo({
              top: topPosition,
              behavior: 'smooth',
            })
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .bgcolor {
    width: 100%;
    background: #333333;
  }
  .content {
    width: 90%;
    margin: 0 auto;
  }
  .one {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 26px;
    color: #ffffff;
    font-weight: 500;
    padding-top: 46px;
  }
  .two {
    display: flex;
    justify-content: space-between;
  }
  .two div {
    cursor: pointer;

    font-family: Alibaba-PuHuiTi-R;
    font-size: 14px;
    color: #ffffff;
    text-align: right;
    font-weight: 400;
    margin-top: 40px;
  }
  .solid1 {
    background: #424242;
    margin: 30px 0;
    width: 100%;
    height: 2px;
  }
  .solid2 {
    background: #424242;
    margin: 30px 0;
    width: 100%;
    height: 2px;
  }
  .info {
    align-items: center;
  }
  .emali,
  .phone,
  .adress,
  .web {
    color: #ffffff;
    align-items: center;
  }
  .bottom {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;

    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #ffffff;

    font-weight: 400;
  }
}
@media screen and (min-width: 1201px) {
  .bgcolor {
    width: 100%;
    background: #333333;
  }
  .content {
    width: 82%;
    margin: 0 auto;
  }
  .one {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 26px;
    color: #ffffff;
    font-weight: 500;
    padding-top: 46px;
  }
  .two {
    display: flex;
    justify-content: space-between;
  }
  .two div {
    cursor: pointer;

    font-family: Alibaba-PuHuiTi-R;
    font-size: 14px;
    color: #ffffff;
    text-align: right;
    font-weight: 400;
    margin-top: 40px;
  }
  .solid1 {
    background: #424242;
    margin: 30px 0;
    width: 100%;
    height: 2px;
  }
  .solid2 {
    background: #424242;
    margin: 30px 0;
    width: 100%;
    height: 2px;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .emali,
  .phone,
  .adress,
  .web {
    display: flex;
    color: #ffffff;
    align-items: center;
  }
  .bottom {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;

    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #ffffff;

    font-weight: 400;
  }
}
</style>
