<template>
  <div class="page">
    <div class="view">
      <img class="aboutthree" src="../assets/aboutthree.png" alt="" />
      <div class="title">物流配送服务平台</div>
      <div class="iconview">
        <div class="iconviewone">
          <img src="../assets/aboutthreeicon1.png" alt="" />
          <div>订单管理</div>
        </div>
        <div class="iconviewone">
          <img src="../assets/aboutthreeicon2.png" alt="" />
          <div>路径规划</div>
        </div>
        <div class="iconviewone">
          <img src="../assets/aboutthreeicon3.png" alt="" />
          <div>实时监控</div>
        </div>
        <div class="iconviewone">
          <img src="../assets/aboutthreeicon4.png" alt="" />
          <div>数据分析</div>
        </div>
      </div>
      <div class="text">
        <div>
          在配送服务平台开发方面，诚飞科技打造了一个高效、智能、便捷的<span
            style="color: #4d8cff"
            >物流配送服务平台</span
          >
        </div>
        <div>
          该平台集成了<span style="color: #4d8cff"
            >订单管理、路径规划、实时监控、数据分析</span
          >等多种功能模块，能够实现对物流无人机的全程管理和调度
        </div>
        <div>
          通过与物流企业、电商平台等合作，公司的配送服务平台能够快速对接客户的订单需求，为客户提供快速、准确的物流配送服务
        </div>
        <div>
          同时，平台还具备强大的数据分析能力，能够对配送过程中的数据进行深度分析，为企业的运营决策提供有力支持
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeAboutTwo',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .text {
    margin-top: 80px;
    text-align: center;
  }
  .text div {
    font-family: Alibaba-PuHuiTi-H;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 26px;
    font-weight: 400;
    line-height: 50px;
  }
  .view {
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    .aboutthree {
      width: 100%;
    }
  }
  .title {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 38px;
    color: #000000;
    letter-spacing: 1.58px;
    text-align: center;
    font-weight: 500;
    margin-top: 50px;
  }
  .iconview {
    padding-top: 81px;
    width: 30%;

    margin: 0 auto;
  }

  .iconviewone {
    margin-top: 50px;
  }
}
@media screen and (min-width: 1201px) {
  .page {
    background-color: #f8f9fb;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 120px;
  }
  .text {
    margin-top: 80px;
    text-align: center;
  }
  .text div {
    font-family: Alibaba-PuHuiTi-H;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 26px;
    font-weight: 400;
    line-height: 50px;
  }

  .view {
    width: 82%;
    margin: 0 auto;

    .aboutthree {
      width: 100%;
    }
  }
  .title {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 38px;
    color: #000000;
    letter-spacing: 1.58px;
    text-align: center;
    font-weight: 500;
    margin-top: 70px;
  }
  .iconview {
    padding-top: 81px;
    width: 80%;

    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}
</style>
