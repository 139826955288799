<template>
  <div class="page">
    <div class="title">
      <div class="content_one">产品介绍</div>
      <div class="content_two">Leading the Future of Delivery</div>
      <div class="solid"></div>
    </div>
    <div class="content">
      <div
        class="contentview_one"
        @mouseenter="showone"
        @mouseleave="hiddenone"
      >
        <div class="SN">01</div>
        <div class="solid2"></div>
        <div class="Name">物流无人机</div>
        <div class="hovertext" v-if="showhovertextone">
          <div>自动巡航</div>
          <div>平稳入窗</div>
          <div>超长续航</div>
          <div>超大载重</div>
        </div>
      </div>
      <div
        class="contentview_two"
        @mouseenter="showtwo"
        @mouseleave="hiddentwo"
      >
        <div class="SN">02</div>
        <div class="solid2"></div>
        <div class="Name">停机坪</div>
        <div class="hovertext" v-if="showhovertexttwo">
          <div>智能开启</div>
          <div>定位服务</div>
          <div>电池充电</div>
          <div>订单校验</div>
        </div>
      </div>
      <div
        class="contentview_three"
        @mouseenter="showthree"
        @mouseleave="hiddenthree"
      >
        <div class="SN">03</div>
        <div class="solid2"></div>
        <div class="Name">智能窗</div>
        <div class="hovertext" v-if="showhovertextthree">
          <div>远程控制</div>
          <div>智能传感</div>
          <div>安全机制</div>
          <div>环境管理</div>
        </div>
      </div>
      <div
        class="contentview_four"
        @mouseenter="showfour"
        @mouseleave="hiddenfour"
      >
        <div class="SN">04</div>
        <div class="solid2"></div>
        <img
          class="icon"
          v-if="!showhovertextfour"
          src="../assets/blueicon.png"
          alt=""
        />

        <img
          class="icon"
          v-if="showhovertextfour"
          src="../assets/whiteicon.png"
          alt=""
        />

        <div class="Name">小程序</div>
        <div class="hovertext" v-if="showhovertextfour">
          <div>快捷下单</div>
          <div>订单追踪</div>
          <div>开窗管理</div>
          <div>优惠满满</div>
        </div>
      </div>
      <div
        class="contentview_five"
        @mouseenter="showfive"
        @mouseleave="hiddenfive"
      >
        <div class="SN">05</div>
        <div class="solid2"></div>
        <div class="Name">商家平台</div>
        <div class="hovertext" v-if="showhovertextfive">
          <div>快捷开店 便捷交互</div>
          <div>用户管理 高效营销</div>
          <div>财务助理 数据分析</div>
          <div>支持移动端 操作更便捷</div>
        </div>
      </div>

      <!-- <img class="viewimg" src="../assets/one.png" alt="" />
      <img class="viewimg" src="../assets/two.png" alt="" />
      <img class="viewimg" src="../assets/three.png" alt="" />
      <img class="viewimg" src="../assets/four.png" alt="" />
      <img class="viewimg" src="../assets/five.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeProduct',

  data() {
    return {
      showhovertextone: false,
      showhovertexttwo: false,
      showhovertextthree: false,
      showhovertextfour: false,
      showhovertextfive: false,
    }
  },

  mounted() {},

  methods: {
    showone() {
      this.showhovertextone = true
    },
    hiddenone() {
      this.showhovertextone = false
    },
    showtwo() {
      this.showhovertexttwo = true
    },
    hiddentwo() {
      this.showhovertexttwo = false
    },

    showfour() {
      this.showhovertextfour = true
    },
    hiddenfour() {
      this.showhovertextfour = false
    },
    showfive() {
      this.showhovertextfive = true
    },
    hiddenfive() {
      this.showhovertextfive = false
    },
    showthree() {
      this.showhovertextthree = true
    },
    hiddenthree() {
      this.showhovertextthree = false
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1201px) {
  .hovertext {
    margin-top: 10%;
  }
  .icon {
    position: absolute;
    margin-left: 12%;
  }
  .hovertext div {
    font-family: Alibaba-PuHuiTi-R;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 2px;
    text-align: center;
    line-height: 29px;
    font-weight: 400;
  }
  .SN {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 28px;
    color: #ffffff;
    letter-spacing: 1.17px;
    text-align: center;
    font-weight: 500;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .solid2 {
    width: 1px;
    height: 100px;
    background-color: #ffffff;
    margin: 0 auto;
  }
  .Name {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 28px;
    color: #ffffff;
    letter-spacing: 1.17px;
    text-align: center;
    font-weight: 500;
    margin-top: 5%;
  }
  .contentview_one:hover {
    background-image: linear-gradient(0deg, #4a5153 0%, #1c2334 100%);
  }
  .contentview_two:hover {
    background-image: linear-gradient(0deg, #4a5153 0%, #2b3951 100%);
  }
  .contentview_three:hover {
    background-image: linear-gradient(0deg, #686868 0%, #373737 100%);
  }
  .contentview_four:hover {
    background-image: linear-gradient(0deg, #4a5153 0%, #2b4951 100%);
  }
  .contentview_five:hover {
    background-image: linear-gradient(0deg, #878686 0%, #504f4f 100%);
  }
  .contentview_one {
    background-image: url('../assets/one.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 20%;
    height: 1.56 * 20vw;
  }
  .contentview_two {
    background-image: url('../assets/two.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 20%;
    height: 1.56 * 20vw;
  }
  .contentview_three {
    background-image: url('../assets/three.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 20%;
    height: 1.56 * 20vw;
  }
  .contentview_four {
    background-image: url('../assets/four.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 20%;
    height: 1.56 * 20vw;
  }
  .contentview_five {
    background-image: url('../assets/five.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 20%;
    height: 1.56 * 20vw;
  }

  .content {
    display: flex;
    width: 100%;
    margin-top: 40px;
  }
  .page {
    background-color: #f8fafb;
  }
  .title {
    margin-top: 190px;
    padding-top: 50px;
  }
  .content_one {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 48px;
    color: #000000;
    letter-spacing: 2px;
    font-weight: 500;
    text-align: center;
  }
  .content_two {
    font-family: Alibaba-PuHuiTi-R;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0.9px;
    text-align: center;
    line-height: 34px;
    font-weight: 400;
    text-align: center;
  }
  .solid {
    background: #4d8cff;
    width: 61px;
    height: 6px;
    margin: 0 auto;
    margin-top: 21px;
  }
}
@media screen and (max-width: 1200px) {
  .hovertext {
    margin-top: 10%;
  }
  .icon {
    position: absolute;
    margin-left: 60%;
  }
  .hovertext div {
    font-family: Alibaba-PuHuiTi-R;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 2px;
    text-align: center;
    line-height: 29px;
    font-weight: 400;
  }
  .SN {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 28px;
    color: #ffffff;
    letter-spacing: 1.17px;
    text-align: center;
    font-weight: 500;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .solid2 {
    width: 1px;
    height: 100px;
    background-color: #ffffff;
    margin: 0 auto;
  }
  .Name {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 28px;
    color: #ffffff;
    letter-spacing: 1.17px;
    text-align: center;
    font-weight: 500;
    margin-top: 5%;
  }
  .contentview_one:hover {
    background-image: linear-gradient(0deg, #4a5153 0%, #1c2334 100%);
  }
  .contentview_two:hover {
    background-image: linear-gradient(0deg, #4a5153 0%, #2b3951 100%);
  }
  .contentview_three:hover {
    background-image: linear-gradient(0deg, #686868 0%, #373737 100%);
  }
  .contentview_four:hover {
    background-image: linear-gradient(0deg, #4a5153 0%, #2b4951 100%);
  }
  .contentview_five:hover {
    background-image: linear-gradient(0deg, #878686 0%, #504f4f 100%);
  }
  .contentview_one {
    background-image: url('../assets/one.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1.56 * 100vw;
  }
  .contentview_two {
    background-image: url('../assets/two.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1.56 * 100vw;
  }
  .contentview_three {
    background-image: url('../assets/three.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1.56 * 100vw;
  }
  .contentview_four {
    background-image: url('../assets/four.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1.56 * 100vw;
  }
  .contentview_five {
    background-image: url('../assets/five.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1.56 * 100vw;
  }

  .content {
    width: 100%;
    margin-top: 40px;
  }
  .title {
    margin-top: 50px;
    background-color: pink;
  }
  .content_one {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 48px;
    color: #000000;
    letter-spacing: 2px;
    font-weight: 500;
    text-align: center;
  }
  .content_two {
    font-family: Alibaba-PuHuiTi-R;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0.9px;
    text-align: center;
    line-height: 34px;
    font-weight: 400;
    text-align: center;
  }
  .solid {
    background: #4d8cff;
    width: 61px;
    height: 6px;
    margin: 0 auto;
    margin-top: 21px;
  }
}
</style>
