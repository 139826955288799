<template>
  <div>
    <div class="view">
      <div class="leftview">
        <div class="text">
          <div class="title">About us ·</div>
          <div class="content_one">关于诚飞</div>
          <div class="content_two">
            诚飞科技（南京）有限公司是一家在物流无人机技术及配送服务平台领域具有卓越创新能力和领先技术水平的科技企业，解决物流配送的
            “最后300米配送入户” 的难题
          </div>
          <div class="btn" @click="goTomore">了解更多</div>
        </div>
      </div>
      <div class="rightview">
        <img class="aboutusimg" src="../assets/aboutusimg.png" alt="" />
      </div>
    </div>
    <!-- -->
    <img class="rectangle" src="../assets/rectangle.png" alt="" />
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeContactUs',

  data() {
    return {}
  },

  mounted() {},

  methods: {
    goTomore() {
      this.$router.push('./more')
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .view {
    margin-top: 50px;
  }
  .leftview {
    width: 100%;
    .text {
      width: 90%;
      margin: 0 auto;

      .title {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 58px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 500;
        margin-top: 20px;
      }
      .content_one {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 32px;
        color: #000000;
        letter-spacing: 1.4px;
        font-weight: 500;
        margin-top: 10px;
      }
      .content_two {
        font-family: Alibaba-PuHuiTi-R;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0.8px;
        text-align: justify;
        line-height: 34px;
        font-weight: 400;
        margin-top: 56px;
        background-color: #f8fafb;
      }
      .btn {
        cursor: pointer;
        background: #4d8cff;
        border-radius: 2px;
        width: 152px;
        height: 46px;
        font-family: Alibaba-PuHuiTi-R;
        font-size: 22px;
        color: #ffffff;
        letter-spacing: 0.96px;
        font-weight: 400;
        text-align: center;
        line-height: 46px;
        margin-top: 10%;
      }
    }
  }
  .rightview {
    width: 100%;
    z-index: 99;
    .aboutusimg {
      width: 90%;
      margin-left: 5%;

      z-index: 998;
    }
  }
  .rectangle {
    width: 100%;
    margin-top: -6%;
  }
}
@media screen and (min-width: 1201px) {
  .view {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
  }
  .leftview {
    width: 50%;
    .text {
      width: 60%;
      margin: 0 auto;

      .title {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 58px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 500;
        margin-top: 20px;
        margin-left: 20px;
      }
      .content_one {
        margin-left: 20px;
        font-family: Alibaba-PuHuiTi-M;
        font-size: 32px;
        color: #000000;
        letter-spacing: 1.4px;
        font-weight: 500;
        margin-top: 10px;
      }
      .content_two {
        font-family: Alibaba-PuHuiTi-R;
        font-size: 16px;
        color: #000000;
        background-color: #f8fafb;
        padding: 20px;
        letter-spacing: 0.8px;
        text-align: justify;
        line-height: 34px;
        font-weight: 400;
        margin-top: 2%;
      }
      .btn {
        cursor: pointer; /* 添加这行代码 */
        background: #4d8cff;
        border-radius: 2px;
        width: 152px;
        height: 46px;
        font-family: Alibaba-PuHuiTi-R;
        font-size: 22px;
        color: #ffffff;
        letter-spacing: 0.96px;
        font-weight: 400;
        text-align: center;
        line-height: 46px;
        margin-top: 10%;
      }
    }
  }
  .rightview {
    width: 50%;
    z-index: 99;
    .aboutusimg {
      width: 60%;
      margin-left: 22%;

      z-index: 998;
    }
  }
  .rectangle {
    width: 100%;
    margin-top: -6%;
    height: 0.102 * 100vw;
  }
}
</style>
