<template>
  <div>
    <div class="view">
      <div class="left">
        <div class="title">解决“最后300米配送入户”难</div>
        <div class="content_one">
          在物流无人机技术开发方面，公司拥有专业的研发团队
        </div>
        <div class="content_two">
          团队成员具备丰富的无人机研发经验和深厚的技术功底，不断探索和突破无人机技术的边界
        </div>
        <div class="content_three">
          诚飞研发的物流无人机采用先进的飞行控制系统、高精度的导航定位技术以及可靠的通信技术，能够在复杂的环境中实现精准飞行和货物的安全运输
        </div>
        <div class="content_four">
          解决物流配送的<span style="color: #4d8cff">“最后300米配送入户”</span>
          难题提供了全新的解决方案
        </div>
      </div>
      <div class="right">
        <img class="Abouttwo" src="../assets/Abouttwo.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeAboutTwo',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .title {
    width: 100%;

    font-size: 38px;
    color: #000000;
    letter-spacing: 1.58px;
    font-weight: 500;
    margin-top: 50px;
  }
  .content_one,
  .content_two,
  .content_three,
  .content_four,
  .content_five {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    line-height: 34px;
    font-weight: 400;
    margin-top: 5%;
  }

  .view {
    padding-top: 50px;
    width: 90%;
    margin: 0 auto;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
    .Abouttwo {
      width: 100%;
    }
  }
}
@media screen and (min-width: 1201px) {
  .title {
    font-size: 38px;
    color: #000000;
    letter-spacing: 1.58px;

    font-weight: 500;
    margin-top: 10%;
  }
  .content_one,
  .content_two,
  .content_three,
  .content_four,
  .content_five {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    line-height: 34px;
    font-weight: 400;
    margin-top: 2%;
    width: 70%;
  }

  .view {
    padding-top: 160px;
    width: 82%;
    margin: 0 auto;
    padding-bottom: 160px;

    display: flex;
  }
  .left {
    width: 70%;
  }
  .right {
    width: 30%;
    .Abouttwo {
      width: 100%;
    }
  }
}
</style>
