<template>
  <div>
    <div class="top">
      <div class="left">
        <img class="logoimg" src="../assets/logo.png" alt="" />
        <div class="name">诚飞智慧科技</div>
      </div>
      <div class="right">
        <div class="index" @click="goToindex">首页</div>
        <div class="chengfei">关于诚飞</div>
      </div>
    </div>
    <About_banner></About_banner>
    <About_two></About_two>
    <About_three></About_three>
    <About_four></About_four>
    <About_five></About_five>
    <morebottom></morebottom>
  </div>
</template>

<script>
import About_banner from './About_banner.vue'
import About_two from './About_two.vue'
import About_three from './About_three.vue'
import About_four from './About_four.vue'
import About_five from './About_five.vue'
import morebottom from './morebottom.vue'

export default {
  name: 'CfsdCodeMore',
  components: {
    About_banner,
    About_two,
    About_three,
    About_four,
    About_five,
    morebottom,
  },
  data() {
    return {}
  },

  mounted() {
    window.scrollTo(0, 0)
  },

  methods: {
    goToindex() {
      this.$router.push('/')
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .top {
    background-color: #ffffff;
    height: 120px;
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 9999;
    top: 0;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 999;
  }
  .left {
    display: flex;
    align-items: center;

    .logoimg {
      width: 30px;
      height: 28px;

      margin-left: 60px;
    }
    .name {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 24px;
      color: #141314;
      font-weight: 500;
      margin-left: 7px;
    }
  }
  .right {
    margin-right: 80px;

    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
  .chengfei {
    cursor: pointer;
    font-size: 14px;
    color: #000000;
    margin: 0 30px;
  }
  .index {
    cursor: pointer;
    font-size: 14px;
    color: #000000;
    opacity: 0.6;
  }
}
@media screen and (min-width: 1201px) {
  .top {
    background-color: #ffffff;
    height: 80px;
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 9999;
    top: 0;
    flex-wrap: wrap;
    z-index: 999;
  }
  .left {
    width: 80%;
    display: flex;
    align-items: center;

    .logoimg {
      width: 30px;
      height: 28px;

      margin-left: 60px;
    }
    .name {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 24px;
      color: #141314;
      font-weight: 500;
      margin-left: 7px;
    }
  }
  .right {
    margin-right: 80px;
    width: 10%;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
  .chengfei {
    cursor: pointer;
    font-size: 14px;
    color: #000000;
  }
  .index {
    cursor: pointer;
    font-size: 14px;
    color: #000000;
    opacity: 0.6;
  }
}
</style>
