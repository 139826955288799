<template>
  <div class="view">
    <div class="leftview">
      <div class="content">
        <div class="content_one">Our Team</div>
        <div class="content_two">我们的</div>
        <div class="content_three">
          团队
          <div class="solid"></div>
          <img class="icon" src="../assets/icon.png" alt="" />
        </div>
        <div class="content_four">
          核心成员包括经验丰富的无人机工程师、创新进取的软件开发者以及专业高效的运营管理人才
        </div>
        <div class="content_five">
          在物流无人机技术开发与配送服务平台建设中，凭借深厚的专业知识、敏锐的行业洞察力和强大的执行力，不断攻克技术难题，开拓市场新领域，为公司的持续发展注入强大动力
        </div>
      </div>
    </div>
    <div class="rightview">
      <img class="teamimg" src="../assets/teamimg.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeContactUs',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .view {
    width: 100%;
    margin-top: 50px;
    
  }
  .leftview {
    width: 100%;
    .content {
      width:90%;
      
      margin-left: 5%;
     
      .content_one {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 58px;
        color: #4d8cff;
        letter-spacing: 0;
        font-weight: 500;
      }
      .content_two {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 48px;
        color: #000000;
        letter-spacing: 2px;
        font-weight: 500;
      }
      .content_three {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 48px;
        color: #000000;
        letter-spacing: 2px;
        font-weight: 500;
        display: flex;
        align-items: center;
        .solid {
          background-color: #808080;
          height: 2px;
          width: 100px;
          margin: 0 30px;
        }
      }
      .content_four {
        font-family: Alibaba-PuHuiTi-R;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0.8px;
        text-align: justify;
        line-height: 34px;
        font-weight: 400;
        margin-top: 44px;
      }
      .content_five {
        margin-top: 20px;
        font-family: Alibaba-PuHuiTi-R;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0.8px;
        text-align: justify;
        line-height: 34px;
        font-weight: 400;
      }
    }
  }
  .rightview {
   
    width: 100%;
  }
  .teamimg {
    width: 90%;
    margin-left: 5%;
  }
}
@media screen and (min-width: 1201px) {
  .view {
    width: 100%;
    margin-top: 140px;
 
    display: flex;
    justify-content: space-between;
  }
  .leftview {
    width: 50%;
    .content {
      width: 70%;
    
      margin-left: 18%;
      margin-top: 10%;
      .content_one {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 58px;
        color: #4d8cff;
        letter-spacing: 0;
        font-weight: 500;
      }
      .content_two {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 48px;
        color: #000000;
        letter-spacing: 2px;
        font-weight: 500;
      }
      .content_three {
        font-family: Alibaba-PuHuiTi-M;
        font-size: 48px;
        color: #000000;
        letter-spacing: 2px;
        font-weight: 500;
        display: flex;
        align-items: center;
        .solid {
          background-color: #808080;
          height: 2px;
          width: 100px;
          margin: 0 30px;
        }
      }
      .content_four {
        font-family: Alibaba-PuHuiTi-R;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0.8px;
        text-align: justify;
        line-height: 34px;
        font-weight: 400;
        margin-top: 44px;
      }
      .content_five {
        margin-top: 20px;
        font-family: Alibaba-PuHuiTi-R;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0.8px;
        text-align: justify;
        line-height: 34px;
        font-weight: 400;
      }
    }
  }
  .rightview {
  
    width: 50%;
  }
  .teamimg {
    width: 70%;
    margin-left: 12%;
  }
}
</style>
