<template>
  <div class="view">
    <div class="leftview">
      <img class="visionimg" src="../assets/visionimg.png" alt="" />
    </div>
    <div class="rightview">
      <div class="graph">
        <div class="circle"></div>
        <div class="solid"></div>
      </div>
      <div class="text">
        <div class="content_one">Our Vision</div>
        <div class="content_two">我们的</div>
        <div class="content_three">愿景</div>
        <div class="content_four">
          致力于打造更加智能、高效、安全的物流无人机服务系统
        </div>
        <div class="content_five">
          以科技创新为翼，以高效服务为导向，飞向更加广阔的未来，让全球各地的人们都能享受到诚飞科技带来的高效物流解决方案
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeContactUs',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .view {
    width: 100%;

    background: #f8f9fb;
  }
  .leftview {
    width: 100%;

    .visionimg {
      width: 90%;
      margin-top: 14%;
      margin-left: 5%;
    }
  }
  .rightview {
    width: 100%;
    display: flex;
  }
  .graph {
    width: 20%;
    display: none;
    margin-top: 12%;

    .circle {
      width: 10px;
      height: 10px;
      background: #000000;
      border-radius: 50%;
      margin-left: 60%;
    }
    .solid {
      background: #7c7c7d;
      width: 2px;
      height: 200px;
      margin-left: 62.5%;
    }
  }
  .text {
    margin-top: 11%;
    width: 90%;
    margin: 0 auto;

    .content_one {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 58px;
      color: #4d8cff;
      letter-spacing: 0;
      font-weight: 500;
    }
    .content_two {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 48px;
      color: #000000;
      letter-spacing: 2px;
      font-weight: 500;
    }
    .content_three {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 48px;
      color: #000000;
      letter-spacing: 2px;
      font-weight: 500;
    }
    .content_four {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0.8px;
      text-align: justify;
      line-height: 34px;
      font-weight: 400;
      margin-top: 44px;
    }
    .content_five {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0.8px;
      text-align: justify;
      line-height: 34px;
      font-weight: 400;
      margin-top: 20px;
    }
  }
}
@media screen and (min-width: 1201px) {
  .view {
    width: 100%;
    height: 680px;
    background: #f8f9fb;
    display: flex;
    justify-content: space-between;
  }
  .leftview {
    width: 50%;

    .visionimg {
      width: 70%;
      margin-top: 14%;
      margin-left: 18%;
    }
  }
  .rightview {
    width: 50%;
    display: flex;
  }
  .graph {
    width: 20%;

    margin-top: 12%;

    .circle {
      width: 10px;
      height: 10px;
      background: #000000;
      border-radius: 50%;
      margin-left: 60%;
    }
    .solid {
      background: #7c7c7d;
      width: 2px;
      height: 200px;
      margin-left: 62.5%;
    }
  }
  .text {
    margin-top: 11%;
    width: 62%;

    .content_one {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 58px;
      color: #4d8cff;
      letter-spacing: 0;
      font-weight: 500;
    }
    .content_two {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 48px;
      color: #000000;
      letter-spacing: 2px;
      font-weight: 500;
    }
    .content_three {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 48px;
      color: #000000;
      letter-spacing: 2px;
      font-weight: 500;
    }
    .content_four {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0.8px;
      text-align: justify;
      line-height: 34px;
      font-weight: 400;
      margin-top: 44px;
    }
    .content_five {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0.8px;
      text-align: justify;
      line-height: 34px;
      font-weight: 400;
      margin-top: 20px;
    }
  }
}
</style>
