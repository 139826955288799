<template>
  <div>
    <div class="view">
      <div class="center">
        <div class="centerview">
          <div class="left">
            <div class="lefttext">
              <div class="title">市场</div>
              <div class="text1">
                在市场拓展方面，诚飞科技积极与国内外的物流企业、电商平台、政府部门等开展合作，共同推动物流无人机技术的应用和发展
              </div>
              <div class="text2">
                公司的业务范围已覆盖多个城市和地区，为众多客户提供了优质的物流配送服务，赢得了客户的高度认可和好评
              </div>
            </div>
          </div>
          <div class="right">
            <img class="aboutfour" src="../assets/aboutfour.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeAboutTwo',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .left {
    width: 100%;
    .lefttext {
      width: 90%;
      margin: 0 auto;
    }
    .title {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 38px;
      color: #000000;
      letter-spacing: 1.58px;

      font-weight: 500;
      margin-top: 10%;
    }
    .text1,
    .text2 {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
      margin-top: 10%;
    }
  }
  .right {
    width: 100%;
     
    .aboutfour {
      width: 90%;
     margin-left: 5%;
    height: 0.706 * 100vw;
   

    }
  }
  .center {
    width: 100%;
    

    margin: 0 auto;
    .centerview {
      width: 100%;

      background-color: white;

      margin: 0 auto;
      margin-top: 16%;
    }
  }
  .view {
    margin-top: 50px;
    
    width: 100%;
  }
}
@media screen and (min-width: 1201px) {
  .left {
    width: 50%;
    .lefttext {
      width: 80%;
      margin: 0 auto;
    }
    .title {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 38px;
      color: #000000;
      letter-spacing: 1.58px;

      font-weight: 500;
      margin-top: 10%;
    }
    .text1,
    .text2 {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
      margin-top: 10%;
    }
  }
  .right {
    width: 50%;
    .aboutfour {
      width: 100%;
    }
  }
  .view {
    width: 100%;
 
    background-image: url('../assets/aboutfourbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0.506 * 100vw;

    .center {
      width: 82%;
      height: 0.506 * 100vw;

      display: flex;
      margin: 0 auto;
      .centerview {
        width: 82%;
        height: 0.233 * 100vw;
        background-color: white;
        display: flex;
        margin: 0 auto;
        margin-top: 16%;
      }
    }
  }
}
</style>
