<template>
  <div>
    <div class="titleview">
      <div class="title">业务模式</div>
      <div class="solid"></div>
      <div class="title_two">Business Model</div>
    </div>
    <div class="hrsolid"></div>

    <div class="content">
      <div class="one">
        <img class="modelone" src="../assets/modelone.png" alt="" />
        <div class="textview">
          <div class="textone">物流无人机</div>
          <div class="texttwo">通过移动端应用快速下单</div>
        </div>
      </div>
      <div class="two">
        <img class="modelone" src="../assets/modeltwo.png" alt="" />
        <div class="textviewtwo">
          <div class="textone">无人机送货</div>
          <div class="texttwo">商家备货后放入无人机货仓，</div>
          <div class="textthree">无人机自动飞行送货</div>
        </div>
      </div>
      <div class="three">
        <img class="modelone" src="../assets/modelthree.png" alt="" />
        <div class="textviewthree">
          <div class="textone">开窗入户</div>
          <div class="texttwo">无人机到达智能窗后，</div>
          <div class="textthree">经用户授权后开窗后飞入室内降落，</div>
          <div class="textfour">送货到家</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeSss',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .one {
    margin-top: 69px;
    background-color: #f8fafb;
  }
  .two {
    margin-top: 40px;
    background-color: #514f4f;
  }
  .three {
    margin-top: 40px;
    background-color: #f8fafb;
  }
  .modelone {
    width: 100%;
  }
  .textviewtwo {
    width: 90%;
    .textone {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 30px;
      margin-top: 15%;
      color: #5d96ff;
      letter-spacing: 1.25px;
      font-weight: 500;
      margin-left: 10%;
    }
    .texttwo {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;
      color: #ffffff;
      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
      margin-top: 20px;
    }
    .textthree {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;
      color: #ffffff;
      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
    }
  }
  .textviewthree {
    width: 90%;
    .textone {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 30px;
      margin-top: 15%;
      color: #5d96ff;
      letter-spacing: 1.25px;
      font-weight: 500;
      margin-left: 10%;
    }
    .texttwo {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;

      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
      margin-top: 20px;
    }
    .textthree {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;

      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
    }
    .textfour {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;

      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
    }
  }
  .textview {
    width: 90%;

    .textone {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 30px;
      color: #4d8cff;
      letter-spacing: 1.25px;
      margin-left: 10%;
      font-weight: 500;
      margin-top: 20%;
    }
    .texttwo {
      margin-left: 10%;
      margin-top: 10px;
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      color: #222222;
      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
    }
  }

  .titleview {
    display: flex;
    margin-top: 180px;
    margin-left: 5%;
    align-items: center;
    .title {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 48px;
      color: #222222;
      letter-spacing: 2px;
      font-weight: 500;
    }
    .solid {
      display: none;
      background: #808080;
      width: 3px;
      height: 30px;
      margin: 0 30px;
    }
    .title_two {
      display: none;

      font-family: Alibaba-PuHuiTi-R;
      font-size: 18px;
      color: #000000;
      letter-spacing: 0.9px;
      line-height: 34px;
      font-weight: 400;
    }
  }
  .hrsolid {
    width: 90%;
    margin: 0 auto;
    background-color: #d8d8d8;
    height: 3px;
    margin-top: 20.5px;
  }
  .content {
    width: 90%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1201px) {
  .one {
    display: flex;
    margin-top: 69px;
    background-color: #f8fafb;
  }
  .two {
    display: flex;
    margin-top: 40px;
    background-color: #514f4f;
  }
  .three {
    display: flex;
    margin-top: 40px;
    background-color: #f8fafb;
  }
  .modelone {
    width: 60%;
  }
  .textviewtwo {
    width: 40%;
    .textone {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 30px;
      margin-top: 15%;
      color: #5d96ff;
      letter-spacing: 1.25px;
      font-weight: 500;
      margin-left: 10%;
    }
    .texttwo {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;
      color: #ffffff;
      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
      margin-top: 20px;
    }
    .textthree {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;
      color: #ffffff;
      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
    }
  }
  .textviewthree {
    width: 40%;
    .textone {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 30px;
      margin-top: 15%;
      color: #5d96ff;
      letter-spacing: 1.25px;
      font-weight: 500;
      margin-left: 10%;
    }
    .texttwo {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;

      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
      margin-top: 20px;
    }
    .textthree {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;

      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
    }
    .textfour {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      margin-left: 10%;

      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
    }
  }
  .textview {
    width: 40%;

    .textone {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 30px;
      color: #4d8cff;
      letter-spacing: 1.25px;
      margin-left: 10%;
      font-weight: 500;
      margin-top: 20%;
    }
    .texttwo {
      margin-left: 10%;
      margin-top: 10px;
      font-family: Alibaba-PuHuiTi-R;
      font-size: 16px;
      color: #222222;
      letter-spacing: 0.8px;
      line-height: 34px;
      font-weight: 400;
    }
  }

  .titleview {
    display: flex;
    margin-top: 180px;
    margin-left: 9%;
    align-items: center;
    .title {
      font-family: Alibaba-PuHuiTi-M;
      font-size: 48px;
      color: #222222;
      letter-spacing: 2px;
      font-weight: 500;
    }
    .solid {
      background: #808080;
      width: 3px;
      height: 30px;
      margin: 0 30px;
    }
    .title_two {
      font-family: Alibaba-PuHuiTi-R;
      font-size: 18px;
      color: #000000;
      letter-spacing: 0.9px;
      line-height: 34px;
      font-weight: 400;
    }
  }
  .hrsolid {
    width: 82%;
    margin: 0 auto;
    background-color: #d8d8d8;
    height: 3px;
    margin-top: 20.5px;
  }
  .content {
    width: 82%;
    margin: 0 auto;
  }
}
</style>
