<template>
  <div>
    <div class="view">
      <div class="text">
        <div class="title">愿景</div>
        <div class="text_one">
          未来，诚飞科技（南京）有限公司将继续秉承 “创新、高效、智能”
          的发展理念，不断提升自身的技术实力和服务水平，为物流行业的发展做出更大的贡献
        </div>
        <div class="text_two">
          致力于成为全球领先的物流无人机技术及配送服务平台提供商
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeAboutTwo',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .view {
    width: 100%;
    background-image: url('../assets/aboutfive.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }
  .text {
    text-align: center;
    padding-top: 13%;
    width: 90%;
    margin: 0 auto;
  }
  .title {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 38px;
    color: #000000;
    letter-spacing: 1.58px;
    text-align: center;
    font-weight: 500;
  }
  .text_one {
    font-family: Alibaba-PuHuiTi-R;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 34px;
    font-weight: 400;
    margin-top: 3%;
  }
  .text_two {
    font-family: Alibaba-PuHuiTi-R;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 34px;
    font-weight: 400;
    margin-top: 1%;
  }
}
@media screen and (min-width: 1201px) {
  .view {
    width: 100%;
    background-image: url('../assets/aboutfive.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0.451 * 100vw;
  }
  .text {
    text-align: center;
    padding-top: 13%;
  }
  .title {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 38px;
    color: #000000;
    letter-spacing: 1.58px;
    text-align: center;
    font-weight: 500;
  }
  .text_one {
    font-family: Alibaba-PuHuiTi-R;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 34px;
    font-weight: 400;
    margin-top: 3%;
  }
  .text_two {
    font-family: Alibaba-PuHuiTi-R;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 34px;
    font-weight: 400;
    margin-top: 1%;
  }
}
</style>
