<template>
  <div>
    <div class="banner">
      <img class="bannerimg" src="../assets/indexbanner.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeIndex',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .banner {
    width: 100%;
    margin-top: 120px;
    .bannerimg {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (min-width: 1201px) {
  .banner {
    width: 100%;
    margin-top: 80px;
    .bannerimg {
      width: 100%;
      height: auto;
    }
  }
}
</style>
