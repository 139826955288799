<template>
  <div>
    <div class="banner">
      <div class="view">
        <div class="title1">About us</div>
        <div class="title2">关于诚飞</div>
        <div class="content">
          诚飞科技（南京）有限公司是一家在<span style="color: #4d8cff"
            >物流无人机技术及配送服务平台</span
          >领域具有卓越创新能力和领先技术水平的科技企业，自成立以来，始终专注于推动物流行业的智能化变革与发展
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CfsdCodeIndex',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .view {
    width: 90%;
    margin: 0 auto;
  }
  .title1 {
    display: none;
    width: 90%;
    margin: 0 auto;
    font-family: Alibaba-PuHuiTi-M;
    font-size: 58px;
    color: #4d8cff;
    letter-spacing: 0;
    font-weight: 500;
    padding-top: 50%;
  }
  .title2 {
    display: none;

    width: 90%;
    margin: 0 auto;
    font-family: Alibaba-PuHuiTi-M;
    font-size: 48px;
    color: #222222;
    letter-spacing: 2px;
    font-weight: 500;
  }
  .content {
    display: none;
    width: 90%;
    margin: 0 auto;
    font-family: Alibaba-PuHuiTi-H;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    text-align: justify;
    line-height: 34px;
    font-weight: 400;
    margin-top: 3%;
  }
  .banner {
    width: 100%;
    margin-top: 120px;
    background-image: url('../assets/bannerdp.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1.106 * 100vw;
    .bannerimg {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (min-width: 1201px) {
  .view {
    width: 82%;
    margin: 0 auto;
  }
  .title1 {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 58px;
    color: #4d8cff;
    letter-spacing: 0;
    font-weight: 500;
    padding-top: 10%;
  }
  .title2 {
    font-family: Alibaba-PuHuiTi-M;
    font-size: 48px;
    color: #222222;
    letter-spacing: 2px;
    font-weight: 500;
  }
  .content {
    width: 30%;
    font-family: Alibaba-PuHuiTi-H;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.8px;
    text-align: justify;
    line-height: 34px;
    font-weight: 400;
    margin-top: 3%;
  }
  .banner {
    width: 100%;
    margin-top: 80px;
    background-image: url('../assets/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0.35 * 100vw;
    .bannerimg {
      width: 100%;
      height: auto;
    }
  }
}
</style>
